// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

@import 'dashboard';

@import 'plugins/preloader';

@import 'plugins/modal_full';

@import 'plugins/toastify';
@import 'plugins/add-homescreen';
//@import 'plugins/loader';


.cursor-pointer {
    cursor: pointer;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

.toastify.bg-danger {
    background: $danger !important;
    color: $white !important;
}

.toastify.bg-success {
    background: $success !important;
    color: $white !important;
}

.modal {
    z-index: 2000;
}

.table-bookings-users {
    height: 500px !important;

    th {
        position: sticky;
        top: 0;
        background: #FFF;
        border: 1px solid $border;
        z-index: 1060;
    }
}
body.modal-open .table-bookings-users th {
    background: inherit;
}

.table-fixed{
    width: 100%;
    background-color: #f3f3f3;
    tbody{
        height:200px;
        overflow-y:auto;
        width: 100%;
    }
    thead,tbody,tr,td,th{
        display:block;
    }
    tbody{
        td{
            float:left;
        }
    }
    thead {
        tr{
            th{
                float:left;
                background-color: #f39c12;
                border-color:#e67e22;
            }
        }
    }
}
